.products{
    padding: 80px 0 80px 0;
    background-repeat: no-repeat;
    background-position: top 50% right;
    background-image: url('../images/bg-4.png');
    @include media(1700){
        background-image: none;
    }
    @include media(576){
        padding: 50px 0 50px 0;
    }
    &.webp{
        background-image: url('../images/bg-4.webp');
        @include media(1700){
            background-image: none;
        }
        .products__item-price{
            background-image: url('../images/price-bg.webp');
        }
    }
    &__title{
        margin: 0px 0px 80px 0px;
        font-size: 33px;
        color: #0180b6;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        @include media(576){
            font-size: 23px;
            margin-bottom: 30px;
        }
    }
    &__content{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 30px;
    }
    &__item{
        width: calc(33.333% - 20px);
        background-color: #fff5d9;
        box-shadow: 0 0px 4px #ffdba4;
        padding: 20px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include media(992){
            width: calc(50% - 15px);
        }
        @include media(768){
            width: 100%;
        }
        &-price{
            background-image: url('../images/price-bg.png');
            background-position: center;
            background-repeat: no-repeat;
            color: white;
            text-align: center;
            font-size: 24px;
            width: 100%;
            font-weight: 500;
            padding: 5px 0 5px 0;
            margin: 0 0 8px 0px;
        }
        &-oldprice{
            font-size: 15px;
            color: #de0000;
            font-weight: 300;
            text-align: center;
            margin: 0 0 11px 0;
        }
        &-title{
            padding: 0px 0px 8px 0px;
            margin: 0;
            font-size: 24px;
            color: #15acec;
            font-weight: 500;
            text-align: center;
        }
        &-p{
            font-size: 14px;
            color: #15acec;
            text-align: center;
            margin: 0 0 30px 0;
        }
        &-btn{
            width: 100%;
            box-shadow: 0 0px 4px #d2a88b;
            font-size: 18px;
            &:not(:last-child){
                margin-bottom: 10px;
            }
            @include media(576){
                height: 60px;
            }
        }
    }
}