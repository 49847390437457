.steps{
    background-repeat: no-repeat;
    background-position: right -50px bottom 10px;
    padding: 80px 0 80px 0px;
    @include media(576){
        padding: 50px 0 50px 0;
    }
    &.webp{
        background-image: none, url('../images/bg-3.webp');
        @include media(1600){
            background-image: none, none;
        }
    }
    &.nowebp{
        background-image: none, url('../images/bg-3.png');
        @include media(1600){
            background-image: none, none;
        }
    }
    &__body{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 30px;
        @include media(1200){
            flex-wrap: wrap;
            gap: 10px;
        }
    }
    &__item{
        width: calc(25% - 20px);
        display: flex;
        align-items: center;
        flex-direction: column;
        @include media(1200){
            width: calc(50% - 10px);
        }
        &-img{
            max-width: 240px;
            width: 100%;
            height: 315px;
            margin-bottom: 30px;
            @include media(1200){
                height: auto;
            }
        }
        &-p{
            font-size: 16px;
            color: #5a5a5a;
            font-weight: 300;
            text-align: center;
            @include media(1200){
                max-width: 300px;
            }
        }
    }
}