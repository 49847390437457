* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
    font-family: 'Ubuntu', sans-serif;
    &:focus,
    &:active {
        outline: none;
    }
}

button {
    font-family: inherit;
    cursor: pointer;
}

input {
    font-family: inherit;
}

a {
    text-decoration: none;
}

.btn{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../images/btn-bg.png');
    background-repeat: repeat-x;
    background-position: bottom;
    background-color: #ffec6e;
    border: 4px solid #ffffff;
    font-size: 24px;
    text-align: center;
    color: #784f15;
    font-weight: 500;
    border-radius: 80px;
    padding: 14px;
    text-shadow: 0px 1px 0 #ffdba4;
    box-shadow: 0 5px 4px #137ab7;
    transition: all 0.3s ease;
    max-width: 500px;
    @include media(576){
        font-size: 16px;
        height: 50px;
    }
    picture{
        margin-right: 5px;
        @include media(576){
            max-width: 40px;
            margin-right: 8px;
            height: 30px;
        }
    }
    &:hover{
        background-position: bottom -40px center;
    }
}

.privacy{
    padding: 50px 15px;
    &__title{
        color: #00B0F0;
        font-size: 16px;
        margin-bottom: 20px;
    }
    &__p{
        font-size: 16px;
        margin-bottom: 20px;
        color: #595959;
    }
    &__subtitle{
        margin-bottom: 20px;
        font-size: 16px;
        color: #595959;
    }
    &__ul{
        padding-left: 16px;
        margin-bottom: 20px;
    }
    &__li{
        color: #595959;
        font-size: 16px;
    }
}