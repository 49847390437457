.header{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 90px;
    &.static{
        background-color: #0070a0;
        position: static;
        height: max-content;
    }
    &__logo{
        width: 250px;
        @include media(576){
            width: 200px;
        }
    }
    &__body{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 15px;
    }
    &__btn{
        font-size: 14px;
        color: #a5e4ff;
        border: 1px solid #a5e4ff;
        border-radius: 80px;
        text-align: center;
        padding: 4px 20px 4px 20px;
        margin-left: 30px;
        transition: all 0.3s ease;
        @include media(576){
            padding: 4px 10px 4px 10px;
        }
        @include media(420){
            font-size: 12px;
            margin-left: 10px;
        }
        &:hover{
            color: #ffffff;
            background-color: #a5e4ff;
            text-decoration: none;
        }
    }
}