.purchase{
    background-color: #25b1ec;
    background-image: url('../images/bg-6.png'), none, none, url('../images/bg-1.png');
    background-repeat: no-repeat, repeat-x, repeat-x, repeat;
    background-position: left -50px bottom 40px, bottom, top, center;
    padding: 100px 0 100px 0px;
    color: white;
    @include media(992){
        background-image: none, none, none, url('../images/bg-1.png');
    }
    @include media(576){
        padding: 50px 0 50px 0px;
    }
    &.webp{
        background-image: url('../images/bg-6.webp'), none, none, url('../images/bg-1.webp');
        @include media(992){
            background-image: none, none, none, url('../images/bg-1.webp');
        }
    }
    &__title{
        margin: 0px 0px 80px 0px;
        font-size: 33px;
        color: #fff;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        @include media(576){
            font-size: 24px;
            margin-bottom: 30px;
        }
    }
    &__content{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 30px;
        @include media(768){
            flex-wrap: wrap;
        }
    }
    &__item{
        width: calc(25% - 80px);
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 300px;
        @include media(992){
            width: calc(25% - 22px);
        }
        @include media(768){
            width: calc(50% - 15px);
        }
        picture{
            margin-bottom: 40px;
            @include media(576){
                margin-bottom: 20px;
            }
        }
        &-title{
            text-align: center;
            font-size: 16px;
            font-weight: 500;
        }
    }
}