.reviews{
    background-color: #fff5d9;
    background-image: url('../images/bg-1_1.png'), none, url('../images/bg-2.png');
    background-repeat: repeat-x, repeat-x, repeat;
    background-position: bottom, top, center;
    padding: 80px 0 80px 0px;
    &.webp{
        background-image: url('../images/bg-1_1.webp'), none, url('../images/bg-2.webp');
    }
    &.nowebp{
        background-image: url('../images/bg-1_1.png'), none, url('../images/bg-2.png');
    }
    &__title{
        margin: 0px 0px 80px 0px;
        font-size: 33px;
        color: #0180b6;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        @include media(576){
            font-size: 24px;
            margin-bottom: 30px;
        }
    }
    &__content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 30px;
        @include media(576){
            align-items: center;
            justify-content: center;
        }
    }
    &__item{
        width: calc(16.666% - 25px);
        max-width: 176px;
        box-shadow: 0 0 10px #d0c196;
        line-height: 0;
        @include media(992){
            width: calc(33.333% - 20px);
        }
        @include media(576){
            width: calc(50% - 15px);
        }
        picture{
            width: 100%;
        }
    }
}