.unique{
    background-color: #25b1ec;
    background-image: url('../images/bg-5.png'), none, none, url('../images/bg-1.png');
    background-repeat: no-repeat, repeat-x, repeat-x, repeat;
    background-position: left -50px bottom 40px, bottom, top, center;
    padding: 100px 0 100px 0px;
    @include media(992){
        background-image: none, none, none, url('../images/bg-1.png');
    }
    @include media(576){
        padding: 50px 0 50px 0px;
    }
    &.webp{
        background-image: url('../images/bg-5.webp'), none, none, url('../images/bg-1.webp');
        @include media(992){
            background-image: none, none, none, url('../images/bg-1.webp');
        }
    }
    &__title{
        margin: 0px 0px 80px 0px;
        font-size: 33px;
        color: white;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        @include media(576){
            margin-bottom: 30px;
            font-size: 24px;
        }
    }
    &__content{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 60px;
    }
    &__item{
        width: calc(33.333% - 20px);
        display: flex;
        flex-direction: column;
        align-items: center;
        @include media(992){
            width: calc(50% - 15px);
        }
        &-p{
            text-align: center;
            color: #fff;
            @include media(576){
                font-size: 14px;
            }
        }
    }
}